<template>
  <div>
    <b-row v-if="content != 'hr'">
      <b-col :cols="cols">
        <p v-if="icon != ''">
          <feather-icon :icon="icon" />&nbsp;&nbsp;{{ label }}
        </p>
        <p v-if="icon == ''">
          {{ label }}
        </p>
      </b-col>
      <b-col>
        <p v-if="bold && type == ''">
          <strong>{{ content }}</strong>
        </p>
        <p v-else-if="bold && type == 'number'">
          <strong>{{ content|numFormat }}</strong>
        </p>
        <p v-else-if="!bold && type == 'number'">
          {{ content|numFormat }}
        </p>
        <p v-else-if="type == 'money'">
          Rp. {{ content|numFormat }}
        </p>
        <p v-else-if="type == 'date'">
          {{ content|moment(formatDate) }}
        </p>
        <p
          v-else-if="type == 'span'"
          class="badge mr-1 "
          :class="content == 'true'?'bg-success':'bg-warning'"
        >
          {{ content == 'true'?dataContent.true:dataContent.false }}
        </p>
        <p
          v-else-if="type == 'spanKey'"
          class="badge mr-1 "
          :class="dataContent[content] && dataContent[content].color?dataContent[content].color:'bg-primary'"
        >
          {{ dataContent[content] && dataContent[content].value?dataContent[content].value:content }}
        </p>
        <p v-else-if="content == 'hide'">
&nbsp;
        </p>
        <p v-else>
          {{ content }}
        </p>
      </b-col>
    </b-row>
    <hr v-if="content == 'hr'">
  </div>
</template>

<script>
import {BRow,BCol} from 'bootstrap-vue'
  export default {
    components: {
      BRow,BCol
    },
    props: {
      icon: {
        type: String,
        default: ''
      },
      content: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      bold: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: ''
      },
      dataContent: {
        type: Object,
        default: function(){return{true:'Ya', false:'Tidak'}}
      },
      formatDate:{
        type:String,
        default:'D MMMM YYYY'
      },
      cols:{
        type:String,
        default:'5'
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>