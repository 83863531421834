<template>
  <app-timeline>
    <app-timeline-item
      v-for="(field, index) in is_reverse?fields.slice().reverse():fields"
      :key="index"
      :title="field.title"
      :subtitle="field.description"
      :variant="is_reverse?index == 0?'info':'secondary':index+1 == fields.length?'info':'secondary'"
    />
  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
    },
    props:{
      fields: {
        type: Array,
        default() {
          return []
        },
      },
      is_reverse:{
        type:Boolean,
        default:false
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>